import React, { useState, useEffect } from "react";
import "./App.css";
import logo from "./assets/LogoWithPron.png";
import logoAndroid from "./assets/android.png";
import logoIos from "./assets/ios.png";
import { getMobileOperatingSystem } from "./utils";
import { FadeLoader } from "react-spinners";

function App() {
    const [os, setOs] = useState("");
    const [loading, setLoading] = useState(true);
    const { innerHeight: height } = window;
    const [meetingSlug, setMeetingSlug] = useState("");
    const [url, setUrl] = useState("");

    useEffect(() => {
        const res = getMobileOperatingSystem();
        setOs(res);
        const currentUrl = window.location.href;
        const a = currentUrl.split("/").length;
        setMeetingSlug(currentUrl.split("/")[a - 1]);
        setUrl(currentUrl);
        setLoading(false);
    }, []);

    return (
        <div className="container">
            {loading ? (
                <div
                    style={{
                        height: height - 60,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <FadeLoader color="#EC408B" />
                </div>
            ) : (
                <div className="container">
                    <h2 className="title mb-5">Únete a la llamada en Amoofy</h2>
                    <img className="logo mb-5" src={logo} alt="logo" />
                    <p className="text-normal">
                        Primero tendrás que descargar la aplicación
                    </p>
                    <div className="btn-container mb-5">
                        <img
                            className="img-btn"
                            src={logoAndroid}
                            alt="logoAndroid"
                        />
                        <img className="img-btn" src={logoIos} alt="logoIos" />
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    {meetingSlug !== "" ? (
                        <div className="fixed">
                            <p className="text-normal">
                                ¿Ya tienes la aplicación?
                            </p>
                            {os === "Android" ? (
                                <a
                                    href={`amoofy://meeting/${meetingSlug}`}
                                    className="btn"
                                >
                                    Unirse a la llamada
                                </a>
                            ) : os === "iOS" ? (
                                <a href={url} className="btn">
                                    Unirse a la llamada
                                </a>
                            ) : (
                                <p>
                                    No puedes unirte a la llamada desde este
                                    dispositivo.
                                </p>
                            )}
                        </div>
                    ) : null}
                </div>
            )}
        </div>
    );
}

export default App;
